
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import EmptyState from '@/components/shared/templates/EmptyState.vue'
import TmWatchTutorial from '@/components/shared/TmWatchTutorial.vue'
import { useModals } from '@/compositions/modals'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'

export default defineComponent({
  components: {
    WhiteBlock,
    EmptyState,
    TmButton,
    TmWatchTutorial,
  },
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
